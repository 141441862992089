import '../App.scss';
import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function HowToPlay() {
  return (
    <div className="private">
      <div className="private-text">
      <h3>Privatlivspolitik for 30 Sekunder</h3>
<p>Denne privatlivspolitik beskriver, hvordan 30 Sekunder håndterer og beskytter brugernes privatliv og personlige oplysninger. Vi er dedikerede til at beskytte dine data og sikre, at enhver information, du deler med os, bliver behandlet i overensstemmelse med gældende love og bestemmelser om databeskyttelse. Vær venlig at læse denne politik omhyggeligt for at forstå vores praksis vedrørende indsamling, brug og videregivelse af dine oplysninger.</p>

<h5>Indsamling af oplysninger</h5>
<p>30 Sekunder indsamler ikke bevidst eller automatisk nogen form for følsomme bruger- eller enhedsdata. Vi indsamler kun de oplysninger, der er nødvendige for at levere spillet og dets funktioner til dig.</p>

<h5>Brugsoplysninger</h5>
<p>Vi kan indsamle visse oplysninger om din brug af 30 Sekunder, såsom enhedsoplysninger, spilpræferencer og spillestatistik. Disse oplysninger bruges udelukkende til at forbedre spiloplevelsen og til at analysere, hvordan spillet fungerer, så vi kan løse eventuelle problemer og fejl.</p>

<h5>Deling af oplysninger</h5>
<p>Vi deler ikke dine personlige oplysninger med tredjepart uden dit udtrykkelige samtykke, medmindre det er påkrævet i henhold til gældende love og bestemmelser. Vi kan dele anonymiserede og aggregerede data om brugeradfærd og spilpræstationer til statistiske formål eller til markedsføringsformål, men disse data vil ikke indeholde personligt identificerbare oplysninger.</p>

<h5>Sikkerhed</h5>
<p>Vi tager passende tekniske og organisatoriske foranstaltninger for at beskytte dine oplysninger mod uautoriseret adgang, ændring, videregivelse eller destruktion. Vi gennemfører løbende revisioner af vores sikkerhedsprocedurer og overvåger vores systemer for at sikre, at dine oplysninger er sikre.</p>

<h5>Tredjepartslinks</h5>
<p>30 Sekunder kan indeholde links til tredjepartswebsites eller -tjenester, som ikke er under vores kontrol. Vi er ikke ansvarlige for indholdet eller privatlivspraksis på sådanne tredjepartswebsteder. Vi opfordrer dig til at læse privatlivspolitikken for ethvert websted, du besøger, inden du deler dine oplysninger med dem.</p>

<h5>Børns privatliv</h5>
<p>30 Sekunder er ikke rettet mod børn. Vi indsamler ikke bevidst personlige oplysninger fra børn. Hvis vi opdager, at vi utilsigtet har indsamlet personlige oplysninger fra et barn, vil vi straks slette disse oplysninger fra vores systemer.</p>

<h5>Ændringer af privatlivspolitikken</h5>
<p>Vi forbeholder os retten til at ændre denne privatlivspolitik efter behov. Eventuelle ændringer vil træde i kraft fra det tidspunkt, de offentliggøres på vores hjemmeside eller gennem spillet. Vi opfordrer dig til at gennemgå denne politik regelmæssigt for at holde dig opdateret om eventuelle ændringer.</p>

<h5>Kontakt os:</h5>
<p>Hvis du har spørgsmål eller bekymringer vedrørende denne privatlivspolitik eller vores behandling af dine oplysninger, er du velkommen til at kontakte os via marcdeleuran@gmail.com.</p>

<p>Denne privatlivspolitik træder i kraft fra 20/05/2023.</p>
      </div>
      <Link to='/'>
        <Button variant='contained' size='small'>
          TILBAGE
        </Button>
      </Link>
    </div>
  );
}

export default HowToPlay;
