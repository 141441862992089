import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import '../App.scss';
import { v4 as uuidv4 } from 'uuid';
import { Box, IconButton, List, ListItem, Modal, TextField, Button } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { OngoingGameContext } from '../contexts/OngoingGameContext';
import { CurrentTeamContext } from '../contexts/CurrentTeamContext';
import { useIsFirstRender } from "@uidotdev/usehooks";

const modalboxstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '15em',
    bgcolor: '#001d3d',
    color: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    textAlign: 'center',
    justifyContent: 'center',
};

function Teams() {

    const { teams, setTeams } = useContext(TeamContext)
    const { ongoingGame, setOngoingGame } = useContext(OngoingGameContext)
    const { setCurrentTeam } = useContext(CurrentTeamContext)

    const [editModalOpen, setEditModalOpen] = useState(false)
    const [value, setValue] = useState(0);

    const handleEditModalOpen = () => setEditModalOpen(true)
    const handleEditModalClose = () => setEditModalOpen(false)

    const [currentTeamEdit, setCurrentTeamEdit] = useState({
        teamname: 'dummy', members: [], points: 0
    });

    const isFirstRender = useIsFirstRender();



    useEffect(() => {
        if (ongoingGame) {
            setTeams([
                { uuid: uuidv4(), teamname: 'Hold 1', members: ['Makker 1'], points: 0, nextPlayer: 0 },
                { uuid: uuidv4(), teamname: 'Hold 2', members: ['Makker 2'], points: 0, nextPlayer: 0 }
            ])
        }
        setOngoingGame(false)
        setCurrentTeam(0)
    }, [value]
    )

    const addTeam = () => {
        const currentTeams = []
        currentTeams.push(...teams)
        currentTeams.push({
            uuid: uuidv4(), teamname: 'Nyt Hold', members: [], points: 0, nextPlayer: 0
        })
        setTeams(currentTeams)
    }

    const addTeamMember = () => {
        const teamWithNewMember = teams.find(t => t.uuid === currentTeamEdit.uuid)
        teamWithNewMember.members.push('Nyt Holdmedlem')
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === currentTeamEdit.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamWithNewMember
        setTeams(currentTeams)
    }

    const startEditTeam = (uuid) => {
        const foundTeam = teams.find(t => t.uuid === uuid)
        setCurrentTeamEdit(foundTeam)
        handleEditModalOpen()
    }

    const handleChangeTeamName = (event) => {
        const teamWithNewName = teams.find(t => t.uuid === event.target.id)
        teamWithNewName.teamname = event.target.value
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === event.target.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamWithNewName
        setTeams(currentTeams)
    }

    const handleChangeTeamMemberName = (index) => (event) => {
        const teamWithTeamMemberName = teams.find(t => t.uuid === currentTeamEdit.uuid)
        teamWithTeamMemberName.members[index] = event.target.value
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === currentTeamEdit.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamWithTeamMemberName
        setTeams(currentTeams)
    }

    const deleteTeam = (uuid) => {
        const currentTeams = []
        for (let i = 0; i < teams.length; i++) {
            if (teams[i].uuid !== uuid) {
                currentTeams.push(teams[i])
            } else {
            };
        }
        setTeams(currentTeams)
    }

    const deleteTeamMember = (memberInput) => {
        const teamToRemoveMemberFrom = teams.find(t => t.uuid === currentTeamEdit.uuid)
        const memberIndex = teamToRemoveMemberFrom.members.findIndex((member) => member === memberInput)
        console.log(teamToRemoveMemberFrom)
        if (memberIndex !== -1)
            teamToRemoveMemberFrom.members.splice(memberIndex, 1)
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === currentTeamEdit.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamToRemoveMemberFrom
        console.log(currentTeams)
        setTeams(currentTeams)
    }

    const canStartGame = () => {
        if (!teams.length > 0) {
            return false
        }
        for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            if (!team.members.length > 0) {
                return false
            }
        }
        return true
    }

    const teamListItems = teams.map((team, index) =>
        <ListItem
            key={index}
            sx={{
                justifyContent: 'space-between'
            }}
        >
            <TextField
                sx={{
                    input: {
                        color: 'white'
                    }
                }}
                variant='standard'
                defaultValue={team.teamname}
                onChange={handleChangeTeamName}
                inputProps={{ maxLength: 15 }}
                id={team.uuid}
            />
            <div>
                <IconButton edge='end' color='primary' aria-label='edit' onClick={() => startEditTeam(team.uuid)}>
                    <PersonOutlineIcon />
                </IconButton>
                {/*<IconButton edge='end' color='primary' aria-label='delete' onClick={() => deleteTeam(team.uuid)}>
                    <DeleteOutlineRoundedIcon />
                </IconButton>*/}
            </div>
        </ListItem>
    );

    const teamMembersListItems = currentTeamEdit.members.map((member, index) =>
        <ListItem key={index} style={{
            padding: 0,
        }}>
            <TextField
                sx={{
                    input: {
                        color: 'white',
                        p: 0.5,
                    },
                }}
                variant='outlined'
                defaultValue={member}
                onChange={handleChangeTeamMemberName(index)}
                inputProps={{ maxLength: 15 }}
            />
            <IconButton edge='end' color='primary' aria-label='delete' onClick={() => deleteTeamMember(member)}>
                <DeleteOutlineRoundedIcon />
            </IconButton>
        </ListItem>
    );

    return (
        <div className="teams">
            <div className="setup">
                <h3 style={{ marginTop: '10px' }}>HOLD OPSÆTNING</h3>
                <div>
                    <List>
                        {teamListItems}
                    </List>
                    <IconButton color='primary' aria-label='add' onClick={() => addTeam()}>
                        <GroupAddIcon />
                    </IconButton>
                    {!canStartGame() ?
                        <Alert sx={{ maxWidth: '20em' }} variant="filled" severity="error">Du mangler at tilføje nogle hold eller har et hold der mangler medlemmer!</Alert> : null}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em',
                    }}
                >
                    <Link to='/'>
                        <Button variant='contained' size='small'>
                            TILBAGE
                        </Button>
                    </Link>
                    {canStartGame() ? 
                    <Link to='/teamsview' onClick={() => setValue(value + 1)}>
                        <Button variant='contained' size='small'>
                            NÆSTE
                        </Button>
                    </Link> : 
                    <Button disabled={true} variant='contained' size='small'>
                        NÆSTE
                    </Button>}
                </div>
            </div>
            <div>
                <Modal
                    open={editModalOpen}
                    onClose={handleEditModalClose}
                >
                    <Box sx={modalboxstyle}>
                        <h1 style={{
                            margin: 0
                        }}>{currentTeamEdit.teamname}</h1>
                        <h2 style={{
                            marginTop: '0.25em',
                            marginBottom: '0'
                        }}>
                            Hold medlemmer
                        </h2>
                        <List sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '9em',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        }}>
                            {teamMembersListItems}
                        </List>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <IconButton color='primary' aria-label='add' onClick={() => addTeamMember()}>
                                <PersonAddAltIcon />
                            </IconButton>
                            <Button variant='contained' size='small' onClick={() => handleEditModalClose()}>
                                GEM
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default Teams;
