import { Route, Routes } from 'react-router-dom';
import { TeamContext } from './contexts/TeamContext';
import './App.scss';
import Home from './pages/Home';
import { useState } from 'react';
import Teams from './pages/Teams';
import { v4 as uuidv4 } from 'uuid';
import Game from './pages/Game';
import Options from './pages/Options';
import Private from './pages/Private';
import TeamsView from './pages/TeamsView';
import { CategoriesOptionsContext } from './contexts/CategoriesOptionsContext';
import { PointsToWinContext } from './contexts/PointsToWinContext';
import { TimerContext } from './contexts/TimerContext';
import HowToPlay from './pages/HowToPlay';
import useStickyState from './functions/stickyState'
import { OngoingGameContext } from './contexts/OngoingGameContext';
import { CurrentTeamContext } from './contexts/CurrentTeamContext';

function App() {
  const [teams, setTeams] = useStickyState([
    { uuid: uuidv4(), teamname: 'Hold 1', members: ['Makker 1'], points: 0, nextPlayer: 0 },
    { uuid: uuidv4(), teamname: 'Hold 2', members: ['Makker 2'], points: 0, nextPlayer: 0 }
  ], "teams")
  const [ongoingGame, setOngoingGame] = useStickyState(false, "ongoinggame");
  const [currentTeam, setCurrentTeam] = useStickyState(0, "currentteam")
  const [categories, setCategories] = useStickyState({
    cars: true,
    movies: true,
    famouspeople: true,
    foods: true,
    famousmusicians: true,
    disneymovies: true,
    videogames: false,
    countries: true,
    capitals: true,
    memes: false,
    anime: false,
    animals: true,
    brands: true,
    danishgeography: true,
    danishtv: true,
  })
  const [pointsToWin, setPointsToWin] = useState(30)
  const [timer, setTimer] = useState(30)

  return (
    <div className="app">
      <div className='header'>
        <h1>30 SEKUNDER</h1>
        <hr style={{
          width: '100%',
          height: '1px',
          marginTop: 5,
          marginBottom: 1,
        }} />
      </div>
      <div className='container' style={{
        marginTop: '10'
      }}>
        <CurrentTeamContext.Provider value={{ currentTeam, setCurrentTeam }}>
          <OngoingGameContext.Provider value={{ ongoingGame, setOngoingGame }}>
            <TimerContext.Provider value={{ timer, setTimer }}>
              <PointsToWinContext.Provider value={{ pointsToWin, setPointsToWin }}>
                <CategoriesOptionsContext.Provider value={{ categories, setCategories }}>
                  <TeamContext.Provider value={{ teams, setTeams }}>
                    <Routes>
                      <Route path='/' element={<Home />} />
                      <Route path='/teamsetup' element={<Teams />} />
                      <Route path='/teamsview' element={<TeamsView />} />
                      <Route path='/game' element={<Game />} />
                      <Route path='/options' element={<Options />} />
                      <Route path='/howtoplay' element={<HowToPlay />} />
                      <Route path='/privatliv' element={<Private />} />
                    </Routes>
                  </TeamContext.Provider>
                </CategoriesOptionsContext.Provider>
              </PointsToWinContext.Provider>
            </TimerContext.Provider>
          </OngoingGameContext.Provider>
        </CurrentTeamContext.Provider>
      </div>
    </div>
  );
}

export default App;
