import '../App.scss';
import * as React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function HowToPlay() {
  return (
    <div className="howtoplay">
      <div className="rules">
        <h3>Hvordan spiller jeg så? 🤔</h3>
        <h5 style={{ marginTop: '1em' }}>30 sekunder er et super simpelt spil du kan spille med dine venner! Vi foreslår at i mindst er 2 hold med 2 spillere på hvert hold🙋‍♀️🙋‍♂️ 🙋‍♀️🙋‍♂️</h5>
        <h5>I skal kun bruge 1 telefon til at spille spillet da telefonen går på tur fra spiller til spiller 📲</h5>
        <h5>Spillet går ud på at få sine holdkammerater til at gætte ord, med det formål at gætte så mange som muligt på 30 sekunder. 🥵⏳</h5>
        <h5>Mens i prøver at forklare ord er der et par begrænsninger i skal overholde! 👇</h5>
        <h5>Forklaringen af ord må ikke indeholde ordet eller dele af ordet!</h5>
        <h5>Man må ikke oversætte ordet til et andet sprog for at give hints til sine holdkammerater!</h5>
      </div>
      <Link to='/'>
        <Button variant='contained' size='small'>
          TILBAGE
        </Button>
      </Link>
    </div>
  );
}

export default HowToPlay;
