import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import Papa from 'papaparse';
import '../App.scss';
import { Button, Checkbox, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { CategoriesOptionsContext } from '../contexts/CategoriesOptionsContext';
import { PointsToWinContext } from '../contexts/PointsToWinContext';
import { TimerContext } from '../contexts/TimerContext';
import { useSound } from 'use-sound';
import { OngoingGameContext } from '../contexts/OngoingGameContext';
import { CurrentTeamContext } from '../contexts/CurrentTeamContext';
import alarm from '../audio/alarm.mp3'
import ticking from '../audio/ticking.mp3'
import famouspeople from '../datasets/famous-people.csv'
import cars from '../datasets/cars.csv'
import ingredients from '../datasets/ingredients.csv'
import moviestop250 from '../datasets/movies-imdb-top-250.csv'
import famousmusicians from '../datasets/famous-musicians.csv'
import disneymovies from '../datasets/disney.csv'
import videogames from '../datasets/video-games.csv'
import countries from '../datasets/countries.csv'
import capitals from '../datasets/capitals.csv'
import memes from '../datasets/memes.csv'
import anime from '../datasets/anime.csv'
import animals from '../datasets/animals.csv'
import brands from '../datasets/brands.csv'
import danishgeography from '../datasets/danish-geography.csv'
import danishtv from '../datasets/danish-television.csv'

function Game() {

    const { teams, setTeams } = useContext(TeamContext)
    const { categories } = useContext(CategoriesOptionsContext)
    const { pointsToWin } = useContext(PointsToWinContext)
    const { timer } = useContext(TimerContext)
    const { setOngoingGame } = useContext(OngoingGameContext)
    const { currentTeam, setCurrentTeam } = useContext(CurrentTeamContext)
    const [value, setValue] = useState(0);
    const [key, setKey] = useState(0);

    const [currentWords, setCurrentWords] = useState(['dummy'])
    const [allWords, setAllWords] = useState(null)

    const [winningTeam, setWinningTeam] = useState({})
    const [pointsToAward, setPointsToAward] = useState(0)

    const [preReadyScreenActive, setPreReadyScreenActive] = useState(true)
    const [readyScreenActive, setReadyScreenActive] = useState(false)
    const [roundActive, setRoundActive] = useState(false)
    const [scoreUpdatingActive, setScoreUpdatingActive] = useState(false)
    const [scoreBoardActive, setScoreBoardActive] = useState(false)
    const [winningScreenActive, setWinningScreenActive] = useState(false)

    const [playTicking] = useSound(ticking);
    const [playAlarm] = useSound(alarm);

    const parseFiles = () => {
        const allWordsMerged = []
        if (categories.famouspeople) {
            Papa.parse(famouspeople, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.cars) {
            Papa.parse(cars, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.foods) {
            Papa.parse(ingredients, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.movies) {
            Papa.parse(moviestop250, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.famousmusicians) {
            Papa.parse(famousmusicians, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.disneymovies) {
            Papa.parse(disneymovies, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.videogames) {
            Papa.parse(videogames, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.countries) {
            Papa.parse(countries, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.capitals) {
            Papa.parse(capitals, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.anime) {
            Papa.parse(anime, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.memes) {
            Papa.parse(memes, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.brands) {
            Papa.parse(brands, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.animals) {
            Papa.parse(animals, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.danishgeography) {
            Papa.parse(danishgeography, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        if (categories.danishtv) {
            Papa.parse(danishtv, {
                header: false,
                delimiter: ';',
                download: true,
                skipEmptyLines: true,
                complete: results => {
                    const merged = [].concat.apply([], results.data)
                    allWordsMerged.push(...merged)
                }
            });
        }
        setAllWords(allWordsMerged)
        setOngoingGame(true)
    };

    useEffect(() =>
        parseFiles(), [value]
    )

    const loadNextWords = () => {
        const nextWords = []
        var arr = [];
        while (arr.length < 5) {
            var r = Math.floor(Math.random() * allWords.length);
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        for (let index = 0; index < 5; index++) {
            nextWords.push(allWords[arr[index]]);
        }
        setCurrentWords(nextWords)
    }

    const handleCheckBoxChecked = (event) => {
        if (event.target.checked === true)
            setPointsToAward(pointsToAward + 1)
        if (event.target.checked === false)
            setPointsToAward(pointsToAward - 1)
    }

    const updateScore = () => {
        const teamWithNewScore = teams[currentTeam]
        teamWithNewScore.points = teamWithNewScore.points + pointsToAward
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === teamWithNewScore.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamWithNewScore
        setTeams(currentTeams)
    }

    const hasTeamWon = () => {
        if (currentTeam + 1 === teams.length) {
            const allPoints = teams.map(team => team.points)
            allPoints.sort((a, b) => b - a)
            if (allPoints[0] !== allPoints[1] && allPoints[0] >= pointsToWin) {
                const teamIndex = teams.findIndex((team) => team.points === allPoints[0])
                if (teamIndex !== -1) {
                    setWinningTeam(teams[teamIndex])
                    setOngoingGame(false)
                }
                return true
            }
        }
    }

    const startScoreUpdating = () => {
        playAlarm()
        setRoundActive(false)
        setScoreUpdatingActive(true)
    }

    const startRound = () => {
        setValue(value + 1)
        loadNextWords()
        setKey(key => key + 1)
        setReadyScreenActive(false)
        setRoundActive(true)
    }

    const startScoreBoard = () => {
        setScoreUpdatingActive(false)
        setScoreBoardActive(true)
    }

    const startNextRound = () => {
        setScoreBoardActive(false)
        updateScore()
        if (hasTeamWon()) {
            setWinningScreenActive(true)
        } else {
            setNextPlayerInTeam()
            setNextTeam()
            setPointsToAward(0)
            setPreReadyScreenActive(true)
        }
    }

    const startReadyScreen = () => {
        setPreReadyScreenActive(false)
        setReadyScreenActive(true)
    }

    const setNextTeam = () => {
        if (currentTeam + 1 === teams.length) {
            setCurrentTeam(0)
        } else {
            setCurrentTeam(currentTeam + 1)
        }
    }

    const setNextPlayerInTeam = () => {
        const teamWithNextPlayer = teams[currentTeam]
        if (teamWithNextPlayer.nextPlayer + 1 === teamWithNextPlayer.members.length) {
            teamWithNextPlayer.nextPlayer = 0
        } else {
            teamWithNextPlayer.nextPlayer = teamWithNextPlayer.nextPlayer + 1
        }
        const currentTeams = []
        currentTeams.push(...teams)
        const teamIndex = currentTeams.findIndex((team) => team.uuid === teamWithNextPlayer.uuid)
        if (teamIndex !== -1)
            currentTeams[teamIndex] = teamWithNextPlayer
        setTeams(currentTeams)
    }

    const resetGame = () => {
        const currentTeams = []
        currentTeams.push(...teams)
        for (let index = 0; index < currentTeams.length; index++) {
            currentTeams[index].points = 0;
            currentTeams[index].nextPlayer = 0;
        }
        setTeams(currentTeams)
    }

    const startTicking = (remaining) => {
        if (remaining === 10) {
            playTicking();
        }
    }

    const currentWordsListItems = currentWords.map((word, index) =>
        <ListItem style={{ textAlign: 'center' }} key={index}>
            <ListItemText primary={word} />
        </ListItem>
    );

    const currentWordsListItemsCheckboxes = currentWords.map((word, index) =>
        <ListItem sx={{
            maxWidth: 300,
            textAlign: 'center'
        }} key={index}>
            <ListItemText primary={word} />
            <Checkbox key={index}
                sx={{
                    color: 'white',
                    '&.Mui-checked': {
                        color: 'primary'
                    },
                }}
                onChange={handleCheckBoxChecked}
            />
        </ListItem>

    );

    const teamWithPointsListItems = teams.map((team, index) =>
        <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell style={{ color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: '1em' }}>{team.teamname}</TableCell>
            <TableCell style={{ color: 'white', paddingTop: 5, paddingBottom: 5, paddingLeft: '1em' }}>
                {currentTeam === index ? team.points + pointsToAward : team.points}
            </TableCell>
        </TableRow>
    );

    return (
        <div className="game">
            <div className="questions">
                {preReadyScreenActive ? <div >
                    <h2 style={{ marginBottom: 10 }}>{teams[currentTeam].teamname} - {teams[currentTeam].members[teams[currentTeam].nextPlayer]}</h2>
                    <h3 style={{ marginTop: 10 }}>Giv telefonen til {teams[currentTeam].members[teams[currentTeam].nextPlayer]}!</h3>
                </div> : null}

                {readyScreenActive ? <div >
                    <h2 style={{ marginBottom: 10 }}>{teams[currentTeam].teamname} - {teams[currentTeam].members[teams[currentTeam].nextPlayer]}</h2>
                    <h3 style={{ marginTop: 10 }}>{teams[currentTeam].members[teams[currentTeam].nextPlayer]} er du klar? Så tryk NÆSTE! 👏</h3>
                </div> : null}

                {roundActive ? <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'space-around'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <h2 style={{ marginBottom: 10 }}>{teams[currentTeam].teamname} - {teams[currentTeam].members[teams[currentTeam].nextPlayer]}</h2>
                        <CountdownCircleTimer
                            size={100}
                            key={key}
                            isPlaying
                            duration={timer}
                            colors={['#ffd60a', '#F7B801', '#A30000', '#5e0000']}
                            colorsTime={[30, 15, 5, 0]}
                            onComplete={() => startScoreUpdating()}
                            onUpdate={(remaining) => startTicking(remaining)}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <List sx={{ width: '100%' }}>
                            {currentWordsListItems}
                        </List>
                    </div>
                </div> : null}

                {scoreUpdatingActive ? <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >

                    <h3 style={{ paddingBottom: '1em' }}>HVILKE FIK DU DINE HOLDKAMMERATER TIL AT GÆTTE? 🤔</h3>
                    <List sx={{
                        width: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {currentWordsListItemsCheckboxes}
                    </List>

                </div> : null}

                {scoreBoardActive ? <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >

                    <h3 style={{ paddingBottom: '1em' }}>HVEM FØRER? 😱</h3>

                    <TableContainer>
                        <Table sx={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: 'white', padding: '1em', paddingBottom: 5 }}>Holdnavn</TableCell>
                                    <TableCell style={{ color: 'white', padding: '1em', paddingBottom: 5 }}>Points</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamWithPointsListItems}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : null}

                {winningScreenActive ? <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >

                    <h1 style={{ paddingBottom: '1em' }}>TILLYKKE {winningTeam.teamname} 🥳</h1>

                    <h4>I SKAL DA HAVE ET SPIL MERE, IKKE? 🥴</h4>
                    <Link to='/teamsetup'>
                        <Button variant='contained' size='small' onClick={() => resetGame()}>
                            JA DA!
                        </Button>
                    </Link>
                </div> : null}
            </div >
            <div className="buttons">
                {preReadyScreenActive || readyScreenActive ?
                <Link className='link' to='/'>
                    <Button 
                        variant='contained' size='small'>
                        HJEM
                    </Button>
                </Link> : null
                }
                {preReadyScreenActive ?
                    <Button
                        sx={{ marginTop: 1 }}
                        variant='contained'
                        size='small'
                        onClick={() => startReadyScreen()}>
                        NÆSTE
                    </Button> : null
                }
                {readyScreenActive ?
                    <Button
                        sx={{ marginTop: 1 }}
                        variant='contained'
                        size='small'
                        onClick={() => startRound()}>
                        NÆSTE
                    </Button> : null
                }
                {scoreUpdatingActive ?
                    <Button variant='contained'
                        size='small'
                        onClick={() => startScoreBoard()}>
                        NÆSTE
                    </Button> : null
                }
                {scoreBoardActive ?
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => startNextRound()}>
                        NÆSTE
                    </Button> : null
                }
            </div>
        </div>
    );
}

export default Game;
