import '../App.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { OngoingGameContext } from '../contexts/OngoingGameContext';

function Home() {

  const { ongoingGame } = useContext(OngoingGameContext)

  return (
    <div className="home">
      {ongoingGame ?
        <Link to='/game'>
          <Button variant='contained' size='small'>
            FORTSÆT SPIL
          </Button>
        </Link> : null}
      <Link to='/teamsetup'>
        <Button variant='contained' size='small'>
          NYT SPIL
        </Button>
      </Link>

      <Link to='/options'>
        <Button variant='contained' size='small'>
          INDSTILLINGER
        </Button>
      </Link>
      <Link to='/howtoplay'>
        <Button variant='contained' size='small'>
          REGLER
        </Button>
      </Link>

    </div>
  );
}

export default Home;
