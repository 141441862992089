import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import '../App.scss';
import { v4 as uuidv4 } from 'uuid';
import { Box, IconButton, List, ListItem, Modal, TextField, Button, ListItemText } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { OngoingGameContext } from '../contexts/OngoingGameContext';
import { CurrentTeamContext } from '../contexts/CurrentTeamContext';

const modalboxstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '15em',
    bgcolor: '#001d3d',
    color: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    textAlign: 'center',
    justifyContent: 'center',
};

function TeamsView() {
    const { teams, setTeams } = useContext(TeamContext)
    const [value, setValue] = useState(0);

    const teamListItem = teams.map((team, index) =>
        <div className='singleTeam'>
            <ListItem
                key={index}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
            >
                <h3>{team.teamname}</h3>
                <div className='members'>
                    {team.members.map((teamMember, index) =>
                        <ListItemText primary={teamMember} />
                    )}
                </div>
            </ListItem>
        </div>
    )

    return (
        <div className="teams">
            <div className="setup">
                <h3 style={{ marginTop: '10px' }}>HOLD</h3>
                <div>
                    <List sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '20em',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        textAlign: 'center'
                    }}>
                        {teamListItem}
                    </List>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em',
                    }}
                >
                    <Link to='/teamsetup'>
                        <Button variant='contained' size='small'>
                            TILBAGE
                        </Button>
                    </Link>
                    <Link to='/game' onClick={() => setValue(value + 1)}>
                        <Button variant='contained' size='small'>
                            START
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default TeamsView;
