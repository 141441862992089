import '../App.scss';
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { CategoriesOptionsContext } from '../contexts/CategoriesOptionsContext';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { PointsToWinContext } from '../contexts/PointsToWinContext';
import { TimerContext } from '../contexts/TimerContext';

function Options() {
  const { categories, setCategories } = React.useContext(CategoriesOptionsContext)
  const { pointsToWin, setPointsToWin } = React.useContext(PointsToWinContext)
  const { timer, setTimer } = React.useContext(TimerContext)

  const handleCategoryChange = (event) => {
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked,
    });
  };

  const handleMaxPointsChange = (event) => {
    setPointsToWin(event.target.value)
  };

  const handleTimerChange = (event) => {
    setTimer(event.target.value)
  }

  return (
    <div className="options">
      <div className="wordlists">
        <h3 style={{ paddingTop: '0.2em' }}>GENERELLE</h3>
        <FormGroup>
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between'
            }}
            control={
              <TextField
                sx={{
                  width: '1em',
                  input: {
                    color: 'white'
                  },
                  paddingRight: '1em'
                }}
                variant='standard'
                defaultValue={pointsToWin}
                onChange={handleMaxPointsChange}

              />
            }
            label="Point for at vinde"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between'
            }}
            control={
              <TextField
                sx={{
                  width: '1em',
                  input: {
                    color: 'white'
                  },
                  paddingRight: '1em'
                }}
                variant='standard'
                defaultValue={timer}
                onChange={handleTimerChange}

              />
            }
            label="Timer"
          />
        </FormGroup>
        <h3 style={{ paddingTop: '1em' }}>KATEGORIER</h3>
        <FormGroup sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '15em',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}>
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.famouspeople} onChange={handleCategoryChange} name="famouspeople" />
            }
            label="Kendte personer"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.famousmusicians} onChange={handleCategoryChange} name="famousmusicians" />
            }
            label="Kendte musikere"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.cars} onChange={handleCategoryChange} name="cars" />
            }
            label="Bilmærker"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.movies} onChange={handleCategoryChange} name="movies" />
            }
            label="Film (Engelske titler)"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.danishtv} onChange={handleCategoryChange} name="danishtv" />
            }
            label="Dansk TV"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.foods} onChange={handleCategoryChange} name="foods" />
            }
            label="Ingredienser"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.animals} onChange={handleCategoryChange} name="animals" />
            }
            label="Dyr"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.countries} onChange={handleCategoryChange} name="countries" />
            }
            label="Verdenslande"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.capitals} onChange={handleCategoryChange} name="capitals" />
            }
            label="Verdens hovedstæder"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.danishgeography} onChange={handleCategoryChange} name="danishgeography" />
            }
            label="Dansk geografi"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.brands} onChange={handleCategoryChange} name="brands" />
            }
            label="Brands (mærker)"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.disneymovies} onChange={handleCategoryChange} name="disneymovies" />
            }
            label="Disney (Danske titler)"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.videogames} onChange={handleCategoryChange} name="videogames" />
            }
            label="Video spil"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.memes} onChange={handleCategoryChange} name="memes" />
            }
            label="Memes"
          />
          <FormControlLabel
            labelPlacement='start'
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              marginRight: '1px'
            }}
            control={
              <Switch checked={categories.anime} onChange={handleCategoryChange} name="anime" />
            }
            label="Anime"
          />
        </FormGroup>
      </div>
      <Link to='/'>
        <Button variant='contained' size='small'>
          TILBAGE
        </Button>
      </Link>
    </div>
  );
}

export default Options;
